import React, { useEffect } from 'react'
import NoFoundImage from 'assets/nothing_found.svg'
import Meta from 'components/modules/SEO/Meta'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { trackEvent } from 'lib/tracking/analytics-service'
import { AnalyticsEventWebPageNotFound } from 'lib/constants/AnalyticsEventType'
import { useRouter } from 'next/router'
import { parseLocale } from 'lib/constants/localization'
import _useTranslation from 'lib/hooks/_useTranslation'
import { StandardHeadline } from 'stories/elements/Typography/Text'
import theme from 'stories/utils/theme'
import styled from 'styled-components'
import { onPageEnter } from 'lib/tracking/pageEnter'

const Container = styled.section`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75vh;
    gap: 20%;
    @media (max-width: 750px) {
        height: 50vh;
    }
    svg {
        margin: 0 auto;
    }
`

const NotFound = () => {
    const { t } = _useTranslation('common')
    const { asPath } = useRouter()

    useEffect(() => {
        onPageEnter()
        trackEvent(AnalyticsEventWebPageNotFound, { value: asPath })
    }, [asPath])

    return (
        <Container>
            <Meta title={t('common/pageNotFoundPageTitle')} description={t('common/pageNotFoundPageDescription')} noIndex />

            <StandardHeadline textAlign="center" color={theme.colors.actionGray}>
                {t('common/pageNotFoundPageTitle')}
            </StandardHeadline>

            <NoFoundImage />
        </Container>
    )
}

export const getStaticProps: GetStaticProps = async (props) => ({
    props: {
        ...(await serverSideTranslations(parseLocale(props.locale).interfaceLanguageCode, ['common', 'homepage'])),
    },
})

export default NotFound
